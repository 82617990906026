import React from "react";
import { Box, Grid, Slide, Typography } from "@mui/material";

import { HeaderStyle, SecondaryStyle } from "../fonts";
import SwipeableTextMobileStepper from "./MobileStepper";
import BigImage from "../assets/Page2Left.webp";
import CarouselImage1 from "../assets/Page2Wheel1.webp";
import CarouselImage2 from "../assets/Page2Wheel2.webp";
import CarouselImage3 from "../assets/Page2Wheel3.webp";
import CarouselImage4 from "../assets/Page2Wheel4.webp";
import SeatingImage from "../assets/Page2BottomRight.webp";
import Gif from "../assets/Page2Video.gif";

const wheelImages = [
  CarouselImage1,
  CarouselImage2,
  CarouselImage3,
  CarouselImage4,
];

const Page2 = (props) => {
  const { checked, isReverse } = props;

  return (
    <Slide
      direction={isReverse ? "right" : "left"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          boxSizing: "border-box",
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          {/* First Half */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              background: ` url(${BigImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: { xs: "5vh", sm: "0vh" },
              borderRight: {
                xs: "none",
                sm: ".5vh solid #000",
              },
              height: {
                xs: "50vh", // Auto height for xs devices
                sm: "100%", // Full height for larger devices
              },
            }}
          ></Grid>

          {/* Second Half */}
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={7}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                    }}
                  >
                    <Typography sx={HeaderStyle}>
                      Gourmet Kitchen with Thermador Appliances
                    </Typography>
                    <Typography sx={SecondaryStyle}>
                      Indulge your inner chef in the top-of-the-line Thermador
                      kitchen equipped with a double oven, a spacious
                      fridge/freezer, a wine fridge, a dishwasher, and a gas
                      range with a sleek range hood.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  >
                    <SwipeableTextMobileStepper
                      images={wheelImages}
                    ></SwipeableTextMobileStepper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${Gif}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderTop: ".5vh solid #000",
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${SeatingImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderTop: ".5vh solid #000",
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default Page2;

import React from "react";
import { Box, Grid, Slide, Typography } from "@mui/material";

import { HeaderStyle, SecondaryStyle } from "../fonts";

import TopImage from "../assets/Page4TopLeft.webp";
import TopRightImage from "../assets/Page4Light.jpg";
import BottomLeftImage from "../assets/Page4Dine.jpg";
import BottomImage from "../assets/Page4Enter.jpg";

const Page4 = (props) => {
  const { checked, isReverse } = props;

  return (
    <Slide
      direction={isReverse ? "right" : "left"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          {/* First Half */}
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={7}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                    }}
                  >
                    <Typography sx={HeaderStyle}>
                      Modern Spa Sanctuary
                    </Typography>
                    <Typography sx={SecondaryStyle}>
                      The primary bathroom is a true showstopper. It features a
                      skylight, a floating tub, a frameless glass surround, and
                      rain shower plumbing.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${TopImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderBottom: ".5vh solid #000",
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${BottomLeftImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderTop: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderTop: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },

                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },

                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography sx={HeaderStyle}>
                      Elegance Meets Flavor: The Dining Room Experience
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Second Half */}
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={7}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderTop: {
                        xs: ".5vh solid #000",
                        sm: "none",
                      },
                    }}
                  >
                    <Typography sx={HeaderStyle}>
                      Abundance of Natural Light
                    </Typography>
                    <Typography sx={SecondaryStyle}>
                      Floor to ceiling windows througout the house on every
                      floor front & back along with three skylights flood the
                      home with natural light.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${TopRightImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${BottomImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderTop: ".5vh solid #000",
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderTop: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        ...HeaderStyle,
                        "@media (orientation: portrait)": {
                          fontSize: { xs: "6vw", sm: "3.5vw" },
                          lineHeight: { xs: "3.3vh", md: "3.5vh" },
                          textAlign: { xs: "center" },
                          paddingTop: { xs: "2vh", md: "3vh" },
                          paddingLeft: { xs: "5vh", sm: "1vh", lg: "3vh" },
                        },
                      }}
                    >
                      Unleashing Fun and Relaxation
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default Page4;

import React from "react";
import { Box, Grid, Slide, Typography } from "@mui/material";
import SwipeableTextMobileStepper from "./MobileStepper";

import { SecondaryStyle, HeaderStyle } from "../fonts";

import BottomLeftImage from "../assets/Page3BedMoney.webp";
import CarouselImage1 from "../assets/Page3BedSlide1.webp";
import CarouselImage2 from "../assets/Page3BedSlide2.webp";
import Bed from "../assets/Page3bed.gif";
import BathGif from "../assets/Page3Bath.gif";
import BCarouselImage1 from "../assets/Page3BathSlide1.webp";
import BCarouselImage2 from "../assets/Page3BathSlide2.webp";
import BCarouselImage3 from "../assets/Page3BathSlide3.webp";
import BCarouselImage4 from "../assets/Page3BathSlide4.webp";

const carouselImages = [CarouselImage1, CarouselImage2];
const bathCarouselImages = [
  BCarouselImage1,
  BCarouselImage2,
  BCarouselImage3,
  BCarouselImage4,
];

const Page3 = (props) => {
  const { checked, isReverse } = props;

  return (
    <Slide
      direction={isReverse ? "right" : "left"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          overflow: { xs: "auto", sm: "hidden" },
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          {/* First Half */}
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={6}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        ...HeaderStyle,
                        "@media (orientation: portrait)": {
                          fontSize: { xs: "6vw", sm: "3.5vw" },
                          lineHeight: { xs: "3.3vh", md: "3.1vh" },
                          textAlign: { xs: "center" },
                          paddingTop: { xs: "2vh", md: "1vh" },
                          paddingLeft: { xs: "1vh", lg: "3vh" },
                        },
                      }}
                    >
                      Discover Tranquility in Every Sunrise
                    </Typography>
                    <Typography
                      sx={{
                        ...SecondaryStyle,
                        "@media (orientation: portrait)": {
                          fontSize: {
                            xs: "1.9vh",
                            lg: "4vh",
                          },
                          lineHeight: { xs: "2.4vh", md: "2.4vh" },
                          paddingTop: { xs: "0vh", md: "1.5vh" },
                          paddingRight: { xs: "2vh" },
                          paddingLeft: { xs: "2vh", lg: "3vh" },
                        },
                      }}
                    >
                      Retreat to your private 3rd-floor primary bedroom deck, an
                      ideal spot to unwind and watch every sunrise. With a
                      similar wall of windows, you’ll enjoy breathtaking views
                      and a sense of serenity.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${Bed}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      backgroundImage: `url('${BottomLeftImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderTop: "4px solid #000",
                      borderBottom: {
                        xs: ".5vh solid #000",
                        sm: "none",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  direction={"row"}
                  sx={{
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      borderBottom: ".5vh solid #000",

                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },

                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  >
                    <SwipeableTextMobileStepper
                      images={carouselImages}
                    ></SwipeableTextMobileStepper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${BathGif}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  direction={"row"}
                  sx={{
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      borderTop: "4px solid #000",
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                    }}
                  >
                    <SwipeableTextMobileStepper
                      images={bathCarouselImages}
                    ></SwipeableTextMobileStepper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderTop: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography sx={HeaderStyle}>
                      Heated Bathrooms and a Spa Like Experience
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default Page3;

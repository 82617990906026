import React from "react";
import { Grid, Slide, Typography, Box } from "@mui/material";
import Cover from "../assets/cover.mp4";
import Remax from "../assets/remax.webp";

import { HeaderStyle, SecondaryStyle } from "../fonts";

const CoverPage = (props) => {
  const { checked, isReverse } = props;

  return (
    <Slide
      direction={isReverse ? "left" : "right"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100vh",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={8} lg={5}>
            <Grid
              container
              direction={"column"}
              sx={{ height: "100%", bgcolor: "#000" }}
            >
              <Grid item xs={8}>
                <video
                  muted
                  autoPlay
                  loop
                  playsInline
                  preload="auto"
                  style={{ height: "65vh", width: "100%", objectFit: "cover" }}
                >
                  <source src={Cover} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
              <Grid item xs={4} sx={{ paddingLeft: "3vh", paddingTop: "3vh" }}>
                <Grid container direction={"row"}>
                  <Grid item xs={12} sm={8}>
                    <Typography
                      variant="h3"
                      sx={{
                        ...HeaderStyle,
                        "@media (orientation: landscape)": {
                          fontSize: { xs: "2.7vw", lg: "2vw" },
                          lineHeight: { xs: "2.5vw", md: "2vw", lg: "2.1vw" },
                          paddingTop: { xs: "0vh" },
                          paddingLeft: { xs: "2vh", md: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: { xs: "7vw", sm: "5vw" },
                          lineHeight: { xs: "3.3vh", md: "3.5vh" },
                          textAlign: { xs: "left" },

                          paddingLeft: { xs: "1vh", lg: "3vh" },
                        },
                      }}
                    >
                      300 Main Street
                    </Typography>
                    <Typography
                      sx={{
                        ...SecondaryStyle,

                        fontWeight: "bold",
                        opacity: 1,
                        "@media (orientation: landscape)": {
                          fontSize: { xs: "1.8vw", md: "1.7vw", lg: "1.15vw" },
                          lineHeight: { xs: "1.7vw", md: "2vw", lg: "1.7vw" },
                          paddingTop: { xs: "0vw", md: ".9vw", lg: "0vw" },
                          paddingRight: { xs: "3vw" },
                          paddingLeft: { xs: "2vh", md: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: {
                            xs: "4vw",
                            sm: "3vw",
                            lg: "5vw",
                          },
                          lineHeight: { xs: "2.vh", md: "2.5vh" },
                          paddingTop: { xs: "0.2vh", md: "1.5vh" },
                          paddingRight: { xs: "2vh" },
                          paddingLeft: { xs: "1vh", lg: "3vh" },
                        },
                      }}
                    >
                      3 + 1 Bedrooms | 4 Baths | 2,470 + 676 Sq Ft
                    </Typography>
                    <Typography
                      sx={{
                        ...SecondaryStyle,
                        fontWeight: "bold",
                        opacity: 1,
                        "@media (orientation: landscape)": {
                          fontSize: { xs: "1.7vw", lg: "1.15vw" },
                          lineHeight: { xs: "1.7vw", md: "2.1vw", lg: "1.7vw" },
                          paddingTop: { xs: "0.2vw", md: ".9vw" },
                          paddingRight: { xs: "3vw" },
                          paddingLeft: { xs: "2vh", md: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: {
                            xs: "4vw",
                            sm: "3vw",
                            lg: "5vw",
                          },
                          lineHeight: { xs: "2.4vh", md: "2.5vh" },
                          paddingTop: { xs: "0vh", md: "1.5vh" },
                          paddingRight: { xs: "2vh" },
                          paddingLeft: { xs: "1vh", lg: "3vh" },
                        },
                      }}
                    >
                      $2,975,000
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      backgroundImage: `url('${Remax}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      "@media (orientation: portrait)": {
                        backgroundSize: { xs: "100%" },
                      },
                      "@media (orientation: landscape)": {
                        backgroundSize: { xs: "60%", md: "65%", lg: "80%" },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default CoverPage;

export const HeaderStyle = {
  paddingBottom: "1vh",
  paddingRight: "1vh",
  color: "#FFF",
  fontFamily: "SF Pro Display",
  fontWeight: "bold",

  "@media (orientation: landscape)": {
    fontSize: "1.9vw",
    lineHeight: { xs: "2.5vw", md: "2.3vw", lg: "2.1vw" },
    paddingTop: { xs: "3vh" },
    paddingLeft: { xs: "2vh", md: "3vh" },
  },
  "@media (orientation: portrait)": {
    fontSize: { xs: "6vw", sm: "3.5vw" },
    lineHeight: { xs: "3.3vh", md: "3.5vh" },
    textAlign: { xs: "center" },
    paddingTop: { xs: "2vh", md: "3vh" },
    paddingLeft: { xs: "1vh", lg: "3vh" },
  },
};

export const SecondaryStyle = {
  paddingBottom: "2vh",

  color: "#FFF",
  fontFamily: "Sommet Regular",
  opacity: 0.8,

  "@media (orientation: landscape)": {
    fontSize: { xs: "1.2vw", md: "1.7vw", lg: "1.15vw" },
    lineHeight: { xs: "1.7vw", md: "2.1vw", lg: "1.7vw" },
    paddingTop: { xs: "0vw", md: ".9vw" },
    paddingRight: "3vw",
    paddingLeft: { xs: "2vh", md: "3vh" },
  },
  "@media (orientation: portrait)": {
    fontSize: {
      xs: "1.9vh",
      sm: "1.6vh",
      lg: "4vh",
    },
    lineHeight: { xs: "2.4vh", md: "2.5vh" },
    paddingTop: { xs: "0vh", md: "1.5vh" },
    paddingRight: { xs: "2vh" },
    paddingLeft: { xs: "2vh", lg: "3vh" },
  },
};

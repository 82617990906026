import React from "react";
import { Box, Grid, Slide, Typography, Link } from "@mui/material";

import { HeaderStyle, SecondaryStyle } from "../fonts";

import Avatar from "../assets/BackCoverAvatar.png";
import Cover from "../assets/BackCover.webp";
import Remax from "../assets/remax.webp";
import Facebook from "../assets/FacebookIcon.webp";
import LinkedIn from "../assets/LinkedInIcon.webp";

const BackCover = (props) => {
  const { checked } = props;

  return (
    <Slide
      direction={"left"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          height: "100vh",
          width: "100%",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={9} lg={5}>
            <Grid
              container
              direction={"column"}
              sx={{ height: "100%", bgcolor: "#000" }}
            >
              <Grid
                item
                xs={6.5}
                sx={{
                  backgroundImage: `url('${Cover}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              <Grid item xs={2}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      ...HeaderStyle,
                      "@media (orientation: landscape)": {
                        fontSize: { xs: "2.7vw", lg: "2vw" },
                        lineHeight: { xs: "2.5vw", md: "2vw", lg: "2.1vw" },
                        paddingTop: "3vh",
                        paddingLeft: { xs: "2vh", md: "3vh" },
                      },
                      "@media (orientation: portrait)": {
                        fontSize: { xs: "7vw", sm: "5vw" },
                        lineHeight: { xs: "3.3vh", md: "3.5vh" },
                        paddingTop: { xs: "2vh", md: "3vh" },
                        paddingLeft: { xs: "2vh", lg: "3vh" },
                      },
                    }}
                  >
                    300 Main Street
                  </Typography>
                  <Typography
                    sx={{
                      ...SecondaryStyle,
                      fontWeight: "bold",
                      opacity: 0.9,
                      "@media (orientation: landscape)": {
                        fontSize: { xs: "1.8vw", sm: "1.6vw", lg: "1.4vw" },
                        lineHeight: "1.7vw",
                        paddingTop: "0vw",
                        paddingRight: "3vw",
                        paddingLeft: { xs: "2vh", md: "3vh" },
                      },
                      "@media (orientation: portrait)": {
                        fontSize: {
                          xs: "1.9vh",
                          lg: "4vh",
                        },
                        lineHeight: { xs: "2.4vh", md: "2.5vh" },
                        paddingTop: { xs: "0vh", md: "1.5vh" },
                        paddingRight: "2vh",
                        paddingLeft: { xs: "2vh", lg: "3vh" },
                      },
                    }}
                  >
                    Architectural Excellence Meets High-Tech Living
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={2.5}
                sx={{
                  paddingLeft: "3vh",
                  paddingTop: "0vh",
                }}
              >
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={3.5}
                    sx={{
                      backgroundImage: `url('${Avatar}')`,
                      backgroundRepeat: "no-repeat",
                      "@media (orientation: portrait)": {
                        backgroundSize: { xs: "90%" },
                      },
                      "@media (orientation: landscape)": {
                        backgroundSize: { xs: "40%", lg: "70%" },
                      },
                    }}
                  />

                  <Grid item xs={5} sx={{ display: "flex" }}>
                    <Grid container direction={"column"}>
                      <Grid item xs={4}>
                        <Link sx={{ textDecoration: "none" }} component="div">
                          <Link
                            href="https://storybook-aboutme.thewebsushi.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Typography
                              sx={{
                                ...SecondaryStyle,
                                opacity: 0.9,
                                paddingBottom: "0vh",
                                textDecoration: "underline",
                                "@media (orientation: landscape)": {
                                  fontSize: {
                                    xs: "1.6vw",
                                    lg: "1.2vw",
                                  },
                                  lineHeight: {
                                    xs: "1.9vw",
                                    md: "2.1vw",
                                    lg: "1.7vw",
                                  },
                                  paddingTop: "0vw",
                                  paddingRight: "3vw",
                                  paddingLeft: { xs: "2vh", md: "3vh" },
                                },
                                "@media (orientation: portrait)": {
                                  fontSize: {
                                    xs: "1.9vh",
                                  },
                                  lineHeight: { xs: "2.4vh", md: "2.5vh" },
                                  paddingTop: { xs: "0vh", md: "1.5vh" },
                                  paddingRight: "2vh",
                                  paddingLeft: { xs: "2vh", lg: "3vh" },
                                },
                              }}
                            >
                              John Smith
                            </Typography>
                          </Link>

                          <Typography
                            sx={{
                              ...SecondaryStyle,
                              opacity: 0.9,
                              paddingBottom: "0vh",
                              "@media (orientation: landscape)": {
                                fontSize: {
                                  xs: "1.6vw",
                                  lg: "1.2vw",
                                },
                                lineHeight: {
                                  xs: "1.9vw",
                                  md: "2vw",
                                  lg: "1.7vw",
                                },
                                paddingTop: "0vw",
                                paddingRight: "3vw",
                                paddingLeft: { xs: "2vh", md: "3vh" },
                              },
                              "@media (orientation: portrait)": {
                                fontSize: {
                                  xs: "1.8vh",
                                  sm: "1.6vh",
                                },
                                lineHeight: { xs: "2.4vh", md: "2.5vh" },
                                paddingTop: { xs: "0vh", md: "1.5vh" },
                                paddingRight: { xs: "2vh" },
                                paddingLeft: { xs: "2vh", lg: "3vh" },
                              },
                            }}
                          >
                            <Link
                              href="tel:+14165555555"
                              sx={{
                                color: "inherit",
                                textDecoration: "none",
                              }}
                            >
                              416-555-5555 <br />
                            </Link>
                            <Link
                              href="mailto:JohnSmith@gmail.com"
                              sx={{
                                color: "inherit",
                                textDecoration: "none",
                              }}
                            >
                              JohnSmith@gmail.com <br />
                            </Link>
                          </Typography>
                        </Link>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid
                          container
                          direction={"row"}
                          sx={{
                            height: "100%",
                          }}
                        >
                          <Grid
                            item
                            xs={3}
                            sx={{
                              backgroundImage: `url('${Facebook}')`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              "@media (orientation: landscape)": {
                                backgroundSize: {
                                  xs: "35%",
                                },
                              },
                              "@media (orientation: portrait)": {
                                backgroundSize: {
                                  xs: "60%",
                                },
                              },
                            }}
                          />
                          <Grid
                            item
                            xs={3}
                            sx={{
                              backgroundImage: `url('${LinkedIn}')`,
                              backgroundRepeat: "no-repeat",
                              "@media (orientation: landscape)": {
                                backgroundSize: {
                                  xs: "35%",
                                },
                              },
                              "@media (orientation: portrait)": {
                                backgroundSize: {
                                  xs: "60%",
                                },
                              },
                              backgroundPosition: "left",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={3.5}
                    sx={{
                      backgroundImage: `url('${Remax}')`,
                      backgroundRepeat: "no-repeat",
                      "@media (orientation: portrait)": {
                        backgroundSize: { xs: "90%" },
                      },
                      "@media (orientation: landscape)": {
                        backgroundSize: { xs: "40%", lg: "70%" },
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={0.7}
                sx={{
                  borderTop: ".5vh solid #fff",
                }}
              >
                <Typography
                  sx={{
                    ...SecondaryStyle,
                    opacity: 0.9,
                    textAlign: "center",
                    "@media (orientation: landscape)": {
                      fontSize: { xs: "1.2vw", md: "1.7vw", lg: ".8vw" },
                      lineHeight: { xs: "1.7vw", md: "2vw", lg: "1.7vw" },
                      paddingTop: "0vw",
                      paddingRight: "3vw",
                      paddingLeft: { xs: "2vh", md: "3vh" },
                    },
                    "@media (orientation: portrait)": {
                      fontSize: {
                        xs: "1.9vh",
                        sm: "1.6vh",
                        lg: "4vh",
                      },
                      lineHeight: "2vh",
                      paddingTop: { xs: "0vh", md: "1.5vh" },
                      paddingRight: "2vh",
                      paddingLeft: { xs: "2vh", lg: "3vh" },
                    },
                  }}
                >
                  Not intended to solicit Sellers or Buyers currently under
                  written contract with another Realtor
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default BackCover;

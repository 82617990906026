import React from "react";
import { Box, Grid, Slide, Typography } from "@mui/material";

import TopRightImage from "../assets/Page5Night.webp";
import Gif from "../assets/Page5.gif";
import BottomLeftImage from "../assets/Page5BottomLeft.webp";
import BottomRightImage from "../assets/Page5BottomRight.webp";
import { HeaderStyle, SecondaryStyle } from "../fonts";

const locationCoordinates = "43.646838,-79.463295";
const apiKey = "AIzaSyAFpNc43_feqaP2L2jnlvzC0XpVYfPcOik";
const mapsUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodeURIComponent(
  locationCoordinates
)}`;

const Page5 = (props) => {
  const { checked, isReverse } = props;

  return (
    <Slide
      direction={isReverse ? "right" : "left"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={6}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        ...HeaderStyle,
                        "@media (orientation: landscape)": {
                          fontSize: "1.9vw",
                          lineHeight: { xs: "2.5vw", md: "2.1vw", lg: "2vw" },
                          paddingTop: "2vh",
                          paddingLeft: "3vh",
                        },
                        "@media (orientation: portrait)": {
                          fontSize: { xs: "6vw", sm: "3.2vw" },
                          lineHeight: { xs: "3.3vh", sm: "2vh", lg: "2.4vh" },
                          textAlign: { xs: "center" },
                          paddingTop: { xs: "2vh", sm: "1vh", lg: "2vh" },
                          paddingLeft: { xs: "2vh", sm: "0.5vh" },
                        },
                      }}
                    >
                      About High Park
                    </Typography>
                    <Typography
                      sx={{
                        ...SecondaryStyle,
                        "@media (orientation: landscape)": {
                          fontSize: {
                            xs: "1.2vw",
                            md: "1.2vw",
                            lg: "1.15vw",
                          },
                          lineHeight: {
                            xs: "1.7vw",
                            md: "1.75vw",
                            lg: "1.9vw",
                          },
                          paddingTop: { xs: "0vw", md: ".2vw" },
                          paddingRight: "2vw",
                          paddingLeft: "3vh",
                        },
                        "@media (orientation: portrait)": {
                          fontSize: {
                            xs: "1.9vh",
                            sm: "1.6vh",
                            lg: "4vh",
                          },
                          lineHeight: { xs: "2.4vh", sm: "1.8vh", lg: "1.9vh" },
                          paddingTop: { xs: "0vh", md: ".5vh", lg: "1.5vh" },
                          paddingRight: { xs: "2vh", sm: "1vh" },
                          paddingLeft: { xs: "2vh", sm: "1vh", lg: "2vh" },
                        },
                      }}
                    >
                      Embrace the pulse of city life in High Park's dynamic
                      urban setting. Just steps away, Bloor Street buzzes with
                      eclectic shops, gourmet restaurants, and vibrant arts and
                      culture scenes. Enjoy the convenience of top-rated schools
                      and easy access to downtown Toronto. This is a
                      neighborhood where career and lifestyle converge, ideal
                      for professionals and families who love the energy of city
                      living.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      height: {
                        xs: "75vh",
                        sm: "100%",
                      },
                    }}
                  >
                    <iframe
                      src={mapsUrl}
                      title="HighPark Map"
                      allowFullScreen
                      style={{
                        height: "98%",
                        width: "98%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  backgroundImage: `url('${BottomLeftImage}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: { xs: "5vh", sm: "0vh" },
                  borderLeft: {
                    xs: "none",
                    sm: ".5vh solid #000",
                  },
                  borderTop: {
                    xs: "none",
                    sm: ".5vh solid #000",
                  },
                  borderBottom: {
                    xs: ".5vh solid #000",
                    sm: "none",
                  },
                  borderRight: {
                    xs: "none",
                    sm: ".5vh solid #000",
                  },

                  height: {
                    xs: "50vh",
                    sm: "100%",
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* Second Half */}
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} sm={6}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${TopRightImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderBottom: ".5vh solid #000",
                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${Gif}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderBottom: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${BottomRightImage}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderTop: ".5vh solid #000",
                      borderRadius: { xs: "5vh", sm: "0vh" },
                      height: {
                        xs: "50vh",
                        sm: "100%",
                      },
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      borderBottom: {
                        xs: "none",
                      },
                      borderLeft: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        ...HeaderStyle,
                        "@media (orientation: landscape)": {
                          fontSize: "1.9vw",
                          lineHeight: { xs: "2vw", lg: "1.8vw" },
                          paddingTop: "2vh",
                          paddingLeft: { xs: "1.5vh", md: "1vh", lg: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: { xs: "6vw", sm: "3.2vw" },
                          lineHeight: { xs: "3.3vh", sm: "2.4vh" },
                          textAlign: { xs: "center" },
                          paddingTop: {
                            xs: "2vh",
                            sm: "1vh",
                            lg: "2vh",
                          },
                          paddingLeft: "0vh",
                        },
                      }}
                    >
                      Getting Around & Out of Town
                    </Typography>
                    <Typography
                      sx={{
                        ...SecondaryStyle,
                        "@media (orientation: landscape)": {
                          fontSize: {
                            xs: "1.2vw",
                            md: "1.2vw",
                            lg: "1.15vw",
                          },
                          lineHeight: { xs: "1.5vw", lg: "1.7vw" },
                          paddingTop: { xs: "0vw", md: ".2vw" },
                          paddingRight: "1vw",
                          paddingLeft: { xs: "1.5vh", md: "1vh", lg: "3vh" },
                        },
                        "@media (orientation: portrait)": {
                          fontSize: {
                            xs: "1.9vh",
                            sm: "1.6vh",
                            lg: "4vh",
                          },
                          lineHeight: {
                            xs: "2.4vh",
                            sm: "1.7vh",
                            md: "1.6vh",
                          },
                          paddingTop: { xs: "0vh", md: ".5vh", lg: "1.5vh" },
                          paddingRight: { xs: "2vh", sm: "1vh" },
                          paddingLeft: { xs: "2vh", sm: "1vh", lg: "2vh" },
                        },
                      }}
                    >
                      Step into the lush tranquility of High Park, Toronto's
                      largest public green space. Your backyard is a natural
                      wonderland, offering peaceful strolls, family picnics
                      under cherry blossoms, and leisurely afternoons by
                      Grenadier Pond. In this community, nature's beauty is
                      woven into the fabric of everyday life, providing a serene
                      escape from the urban hustle. A haven for outdoor
                      enthusiasts and those seeking a peaceful, green living
                      space.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default Page5;

import React from "react";
import { Box, Grid, Slide, Typography, Skeleton } from "@mui/material";

import TopLeftImage from "../assets/Page1TopLeft.webp";
import BottomLeftImage from "../assets/Page1BottomLeft.webp";
import FirePlaceGif from "../assets/Page1Vid.gif";

import { HeaderStyle, SecondaryStyle } from "../fonts";

const matterportUrl =
  "https://my.matterport.com/show/?m=fRGL4VSosLW&help=0&play=1&brand=0";

const Page1 = (props) => {
  const { checked, isReverse } = props;

  return (
    <Slide
      direction={isReverse ? "right" : "left"}
      in={checked}
      timeout={{
        enter: 1750,
        exit: 1750,
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} sm={6}>
            {/* First Half Container */}
            <Grid
              container
              columnSpacing={0}
              direction={{ xs: "row", sm: "column" }}
              sx={{ height: "100%" }}
            >
              {/* First Half Text */}
              <Grid
                item
                xs={12}
                sm={5}
                sx={{
                  borderRight: {
                    xs: "none",
                    sm: ".5vh solid #000",
                  },
                  borderBottom: {
                    sm: "1vh solid #000",
                  },
                }}
              >
                <Typography sx={HeaderStyle}>
                  Serene Evenings in Your Living Room Retreat
                </Typography>

                <Typography sx={SecondaryStyle}>
                  Picture savoring your preferred drink in the cozy embrace of
                  your living room, watching the sun elegantly set from your
                  personal sanctuary.
                </Typography>
              </Grid>
              {/* Second Half */}
              <Grid item xs={12} sm={7}>
                <Grid container direction={"row"} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      backgroundImage: `url('${FirePlaceGif}')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",

                      borderRight: {
                        xs: "none",
                        sm: ".5vh solid #000",
                      },
                      borderBottom: {
                        xs: ".5vh solid #000",
                        sm: "none",
                      },

                      height: {
                        xs: "50vh", // Auto height for xs devices
                        sm: "100%", // Full height for larger devices
                      },
                      borderRadius: { xs: "5vh", sm: "0vh" },
                    }}
                  />
                  <Grid item xs={12} sm={6}>
                    <Grid
                      container
                      direction={{ xs: "row", sm: "column" }}
                      sx={{ height: "100%" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          backgroundImage: `url('${TopLeftImage}')`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRight: {
                            xs: "none",
                            sm: ".5vh solid #000",
                          },
                          borderBottom: {
                            xs: "none",
                            sm: ".5vh solid #000",
                          },
                          borderLeft: {
                            xs: "none",
                            sm: ".5vh solid #000",
                          },
                          borderRadius: { xs: "5vh", sm: "0vh" },
                          height: {
                            xs: "50vh",
                            sm: "100%",
                          },
                        }}
                      />
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          backgroundImage: `url('${BottomLeftImage}')`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRight: {
                            xs: "none",
                            sm: ".5vh solid #000",
                          },
                          borderTop: ".5vh solid #000",
                          borderLeft: {
                            xs: "none",
                            sm: ".5vh solid #000",
                          },
                          borderBottom: {
                            xs: ".5vh solid #000",
                            sm: "none",
                          },
                          borderRadius: { xs: "5vh", sm: "0vh" },
                          height: {
                            xs: "50vh", // Auto height for xs devices
                            sm: "100%", // Full height for larger devices
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              height: {
                xs: "100vh", // Auto height for xs devices
                sm: "100%", // Full height for larger devices
              },
            }}
          >
            {checked ? (
              <iframe
                src={matterportUrl}
                title="Matterport"
                allowFullScreen
                style={{
                  borderLeft: {
                    xs: "none",
                    sm: ".5vh solid #000",
                  },

                  width: "99%",
                  height: "99%",
                }}
              />
            ) : (
              <Skeleton animation="wave" height="100%" width="100%" />
            )}
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};

export default Page1;
